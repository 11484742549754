<template>

<v-card> 
  
  
  <v-footer  dark color="secondary" height="100px">   </v-footer>

  </v-card>
</template>
<script>
export default {
  name: "Footer"
};
</script>
<style lang="scss">
.v-footer {
  padding-top: 3rem;

  
  padding-bottom:   3rem;
}
</style>
