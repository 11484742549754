var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:{ 'fixed-app-bar': _vm.isFixedAppBar }},[_c('v-container',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_c('div',[_c('a',{attrs:{"href":"javascript:window.location.reload(true);"}},[_c('v-img',{staticClass:"me-2 logo d-md-block d-none",attrs:{"max-width":"170px","max-height":"64px","src":_vm.config.logo,"alt":""}})],1)])]),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"search-bar d-flex p-relative"},[_c('v-text-field',{attrs:{"type":"text","placeholder":" article / keyword / product","filled":"","rounded":"","hide-details":"","dense":"","prepend-inner-icon":"mdi-magnify"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.searchQuery && _vm.$vuetify.breakpoint.width >= 600)?_c('span',{staticClass:"clear-input",on:{"click":_vm.clearSearchQuery}},[_vm._v(" × ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.$vuetify.breakpoint.width >= 600)?_c('v-btn',{staticClass:"text-capitalize search-bar-dropdown px-10 font-600 search-button",attrs:{"color":"primary"},on:{"click":_vm.searchProducts}},[_vm._v(" Search ")]):_vm._e()],1),_c('div',{staticClass:"top-priority"},[_c('ul',_vm._l((_vm.suggests),function(suggest,index){return _c('li',{key:index,class:{ selected: index === _vm.selectedIndex }},[_c('v-list-item',{staticClass:"list-item",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectSuggestion(suggest.suggest)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.selectSuggestion(suggest.suggest)},"mouseover":function($event){return _vm.handleMouseOver(index)},"mouseleave":_vm.handleMouseLeave}},[_c('v-icon',{staticClass:"no-animation",class:{
                    'white--text': index === _vm.selectedIndex,
                    highlighted: index === _vm.selectedIndex
                  }},[_vm._v("mdi-magnify")]),_vm._v("     "),_c('span',{class:{
                    'white-font': index === _vm.selectedIndex,
                    highlighted: index === _vm.selectedIndex
                  }},[_vm._v(" "+_vm._s(suggest.suggest))])],1)],1)}),0)])]),_c('div',{},[_c('div',{staticClass:"d-md-block d-none"})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }