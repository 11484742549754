<template>
 
  <Index />
</template>

<script>
import Index from './layouts/base/Index.vue';
export default {
  name: "App",
  components: {
    Index,
  }
};
</script>

<style lang="scss"></style>
